<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'app-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'app-account-list', params: { realm: this.$route.params.realmId } }">Accounts</router-link>
                    <router-link :to="{ name: 'realm-dashboard', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0" v-if="app">App {{ app.id }}</p>
                    <v-card>
                        <v-toolbar color="indigo" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Application
                        </v-toolbar-title>
                        </v-toolbar>
                        <!-- <RealmAppList :list="list" :accountId="accountId" :realmId="realmId"></RealmAppList> -->
                        <RealmAppViewCardText :attr="app" :accountId="accountId" :realmId="realmId" v-if="app"></RealmAppViewCardText>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
// import RequireLogin from '@/components/RequireLogin.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';
import RealmAppViewCardText from '@/components/realm-dashboard/RealmAppViewCardText.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
        // RequireLogin,
        RealmAppViewCardText,
    },
    data: () => ({
        realm: null,
        app: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            // enterpriseAppList: (state) => state.enterpriseAppList,
        }),
        ...mapGetters({
            // currentAccount: 'account',
        }),
        accountId() {
            return this.$route.params.accountId;
        },
        realmId() {
            return this.$route.params.realmId;
        },
        appId() {
            return this.$route.params.appId;
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.accountId).realm.get({ id: this.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadApp() {
            try {
                this.$store.commit('loading', { loadApp: true });
                const result = await this.$client.accountRealm(this.accountId, this.realmId).realmApp.get({ id: this.appId });
                console.log(`realm/loginshield.vue loadApp result: ${JSON.stringify(result)}`);
                if (result) {
                    this.app = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadApp failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load app', message });
            } finally {
                this.$store.commit('loading', { loadApp: false });
            }
        },
        /*
        async init() {
            const { id } = this.$route.query;
            if (id) {
                const result = await this.$client.app(this.$route.params.realmId).get(id); // NOTE: this is for global apps, like admin apps themselves, not realm apps.
                console.log(`AppView.vue init result: ${JSON.stringify(result)}`);
                if (result) {
                    this.app = result;
                }
            } else {
                this.error = 'bad request';
            }
        },
        */
    },
    mounted() {
        this.loadRealm();
        this.loadApp();
    },
};
</script>

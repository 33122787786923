<template>
    <v-card-text>
        <!-- <p>Name: {{ displayName }}</p> -->
        <p class="text-overline mb-0 mt-4">Name</p>
        <p class="mb-0 pb-0 mt-0">
        <EditableText label="Name" :value="displayName" @input="saveAppDisplayName" dense/>
        </p>

         <!-- TODO: editable with checkbox -->
        <!-- <p class="subtitle mt-2">Active: {{ activeText }}</p> -->
        <!-- <p class="text-overline mb-0 mt-4">Active</p> -->
        <p>
            <v-checkbox v-model="isActive" @change="saveAppActive">
                <template #label>
                    Active
                </template>
            </v-checkbox>
        </p>

        <p class="subtitle mt-2">Destination Tags</p>
        <p>
            <v-chip label small v-for="(tag, idx) in tagList" :key="idx" class="ml-1 pr-1">
                <!-- <font-awesome-icon color="grey" :icon="['fas', 'tag']" size="1x" class="mr-2 grey--text"></font-awesome-icon> -->
                {{ tag }}
                <v-btn icon x-small color="black" @click="deleteTag = tag; displayDeleteTagDialog = true">
                    <font-awesome-icon color="grey" :icon="['fas', 'times']" size="1x"></font-awesome-icon>
                </v-btn>
            </v-chip>
            <v-btn icon color="indigo" @click="displayCreateTagDialog = true">
                <font-awesome-icon color="blue darken-2" :icon="['fas', 'plus']" size="1x"></font-awesome-icon>
            </v-btn>
        </p>
        <v-dialog v-model="displayCreateTagDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="blue--text text--darken-2">Add a destination tag</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                    <p>Destination tags allow other applications to send users to this application via SSO.</p>
                    <p>After you add the tag here, configure the other applications to redirect users to SSO with this
                        tag when the user should be redirected to this application.</p>
                <v-form @submit="createAppTag" onSubmit="return false;" @keyup.enter.native="createAppTag">
                    <v-text-field
                        ref="newTagInput"
                        v-model="newTag"
                        label="Tag"
                        color="blue darken-2"
                        required
                        hint="Tag for this application"
                        type="text"
                        outlined
                    >
                    </v-text-field>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="blue darken-2 white--text" @click="createAppTag" :disabled="!isCreateTagFormComplete">
                        <span>Create</span>
                    </v-btn>
                    <v-btn text color="grey" @click="displayCreateTagDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="displayDeleteTagDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="blue--text text--darken-2">Remove a destination tag</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                    <p>Destination tags allow other applications to send users to this application via SSO.</p>
                    <p>The following tag will be remoted from this app:</p>
                    <!-- TODO: can we show which apps have recently used this tag to redirect a user to this app, when the most recent one was, and maybe how often used in the last 30 days? we should keep track of it in the log so we can do a query -->
                    <!-- TODO: if the tag hasn't been used (see above comment) then show a checkmark with a message saying it hasn't been used in 30 days; if it's been used recently show an exclamation triangle and ask user to confirm they want to delete it, because it would break that integration -->
                <v-form @submit="deleteAppTag" onSubmit="return false;" @keyup.enter.native="deleteAppTag">
                    <v-chip label small>
                        <!-- <font-awesome-icon :icon="['fas', 'tag']" size="1x" class="mr-2 grey--text"></font-awesome-icon> -->
                        {{ deleteTag }}
                    </v-chip>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="blue darken-2 white--text" @click="deleteAppTag" :disabled="!isDeleteTagFormComplete">
                        <span>Delete</span>
                    </v-btn>
                    <v-btn text color="grey" @click="displayDeleteTagDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- TODO: button to set brandprofile -->
        <!-- <p class="subtitle">Brandprofile: {{ brandprofile }}</p>
        <p class="subtitle blue--text text--darken-4" v-if="!brandprofile">
            <font-awesome-icon :icon="['fas', 'info-circle']" size="1x"></font-awesome-icon>
            Customize the app icon with a BrandProfile
        </p> -->
        <p class="text-overline mb-0 mt-4">Brandprofile</p>
        <!-- <p class="subtitle" v-if="brandprofile">{{ brandprofile }}</p> -->
        <!-- TODO: replace this editable text with the brandprofile webauthz selection mechanism -->
        <p class="mb-0 pb-0 mt-0">
        <EditableText label="BrandProfile" :value="brandprofile" @input="saveAppBrandprofile" dense/>
        </p>
        <!-- <p class="subtitle blue--text text--darken-4" v-if="!brandprofile">
            <font-awesome-icon :icon="['fas', 'info-circle']" size="1x"></font-awesome-icon>
            Customize the app icon with a BrandProfile
        </p> -->

        <p class="text-overline mb-0 mt-4">SSO Token URL</p>
        <p class="mb-0 pb-0 mt-0">
        <EditableText label="SSO Token URL" :value="ssoTokenUrl" @input="saveAppSsoTokenUrl" dense/>
        </p>
        <p class="subtitle amber--text text--darken-4" v-if="!ssoTokenUrl">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="1x"></font-awesome-icon>
            You must set the SSO Token URL for this app to be usable as a destination
        </p>
        <!-- <p class="subtitle">Id: {{ id }}</p> -->
        <p class="subtitle mt-6">Created on: {{ createdOn }}</p>
    </v-card-text>
</template>

<script>
import EditableText from '@/components/EditableText.vue';

export default {
    components: {
        EditableText,
    },
    props: {
        attr: {
            type: Object,
        },
        accountId: {
            type: String,
        },
        realmId: {
            type: String,
        },
    },
    data: () => ({
        app: null,
        displayCreateTagDialog: false,
        displayDeleteTagDialog: false,
        newTag: null,
        tagList: [],
        submitTimestamp: null,
        deleteTag: null,
        isActive: null,
    }),
    computed: {
        id() {
            return this.app?.id;
        },
        displayName() {
            return this.app?.display_name;
        },
        activeText() {
            return this.app?.is_active ? 'Yes' : 'No';
        },
        brandprofile() {
            return this.app?.brandprofile;
        },
        ssoTokenUrl() {
            return this.app?.sso_token_url;
        },
        createdOn() {
            return new Date(this.app?.created_on).toString();
        },
        json() {
            return JSON.stringify(this.app, null, 2);
        },
        isCreateTagFormComplete() {
            return typeof this.newTag === 'string' && this.newTag.length > 0;
        },
        isDeleteTagFormComplete() {
            return typeof this.deleteTag === 'string' && this.deleteTag.length > 0;
        },
    },
    watch: {
        attr(newValue) {
            this.app = newValue;
            if (Array.isArray(newValue.tag_list)) {
                this.tagList = [...newValue.tag_list];
            }
            this.isActive = newValue.is_active;
        },
        displayCreateTagDialog(newValue) {
            if (newValue) {
                this.newTag = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newTagInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async saveAppAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveAppAttr: true });
                const response = await this.$client.accountRealm(this.accountId, this.realmId).realmApp.edit({ id: this.id }, { [name]: value });
                console.log(`saveAppAttr: response ${JSON.stringify(response)}`);
                if (!response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit app' });
                    return false;
                }
                this.$set(this.app, name, value);
                return true;
            } catch (err) {
                console.error(`failed to edit app attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit app' });
                return false;
            } finally {
                this.$store.commit('loading', { saveAppAttr: false });
            }
        },
        // async saveAppTagList(value) {
        //     await this.saveAppAttr('tag_list', value); // TODO: maybe use a separate API to add/remove tags? instead of setting the entire list
        // },
        async saveAppDisplayName(value) {
            await this.saveAppAttr('display_name', value);
        },
        async saveAppActive(value) {
            const isEdited = await this.saveAppAttr('is_active', value);
            if (isEdited) {
                // this.isActive = value;
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
            }
            if (!isEdited) {
                // restore prior value
                this.isActive = !value;
            }
        },
        async saveAppBrandprofile(value) {
            await this.saveAppAttr('brandprofile', value);
        },
        async saveAppSsoTokenUrl(value) {
            await this.saveAppAttr('sso_token_url', value);
        },
        async createAppTag() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.error = false;
                this.$store.commit('loading', { createAppTag: true });
                const response = await this.$client.accountRealm(this.accountId, this.realmId).realmAppTag.create({ app_id: this.id, tag: this.newTag });
                console.log(`createAppTag: response ${JSON.stringify(response)}`);
                if (response?.isCreated) {
                    const list = [...this.tagList];
                    list.push(this.newTag);
                    this.tagList = list;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create tag' });
                }
                this.displayCreateTagDialog = false;
            } catch (err) {
                console.error(`failed to create tag: ${JSON.stringify(this.newTag)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create tag' });
            } finally {
                this.$store.commit('loading', { createAppTag: false });
            }
        },
        async deleteAppTag(tag) {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteAppTag: true });
                const response = await this.$client.accountRealm(this.accountId, this.realmId).realmAppTag.delete({ app_id: this.id, tag: this.deleteTag });
                console.log(`deleteAppTag: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    const list = [...this.tagList];
                    const idx = list.indexOf(tag);
                    list.splice(idx, 1);
                    this.tagList = list;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete tag' });
                }
                this.displayDeleteTagDialog = false;
            } catch (err) {
                console.error(`failed to delete tag: ${JSON.stringify(this.newTag)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete tag' });
            } finally {
                this.$store.commit('loading', { deleteAppTag: false });
            }
        },
    },
    mounted() {
        this.app = this.attr;
        if (Array.isArray(this.attr.tag_list)) {
            this.tagList = [...this.attr.tag_list];
        }
        this.isActive = this.attr.is_active;
    },
};
</script>
